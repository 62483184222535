import styled from 'styled-components'

export const Card = styled.div`
    border-radius: 0.2em;
    background-color: white;
    height: calc(100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);

`