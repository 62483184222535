import { Merete } from './Merete'
import { Irma } from './Irma'
import { Tom } from './Tom'
import { Rachel } from './Rachel'
import { Walter } from './Walter'
import { Austin } from './Austin'
import { Martin } from './Martin'
import { Jimbo } from './Jimbo'
import { Heart } from './Heart'
import { Rene } from './Rene'
import { Papaya } from './Papaya'
import { Ella } from './Ella'

interface IAlbumMetadata {
  trackMetadata: any,
  metadata: any
}


export const AlbumMetadata: IAlbumMetadata = {
  trackMetadata: {
    Merete,
    Irma,
    Tom,
    Rachel,
    Walter,
    Austin,
    Martin,
    Jimbo,
    Heart,
    Rene,
    Papaya,
    Ella,
  },
  metadata: {
    title: "Names",
    year: 2020,
    artist: "Thadeus",
    trackOrder: [
      "Austin",
      "Tom",
      "Walter",
      "Merete",
      "Martin",
      "Irma",
      "Rachel",
      "Jimbo",
      "Heart",
      "Rene",
      "Papaya",
      "Ella",
    ]
  }
}