import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Track } from '../tracks';
import {
	AudioPlayerContainer
} from './styles'

import {
	scroller
} from "react-scroll";

import { CgNotes } from "react-icons/cg";
import { FaItunesNote } from 'react-icons/fa';

function AudioTag(props: any) {

	console.log('___')

	return (
		<audio
			id="audioPlayer"
			src={props.audioSrc}
			controls
			preload="auto"
			ref={props.audioElementRef}
		>
			Your browser does not support the audio element.
		</audio>
	)
}


export const AudioPlayer = ({ tracks, albumMetadata }: {
	tracks: Track[], albumMetadata: {
		albumCoverImages: {
			sm: string,
			md: string,
			lg: string
		}
	}
}) => {

	const audioElementRef = useMemo(() => React.createRef<HTMLAudioElement>(), []);
	const isReady = useRef(false);

	const [trackIndex, setTrackIndex] = useState(0);

	const { 
		title, 
		// artist, 
		audioSrc 
	} = tracks[trackIndex];

	useEffect(() => {
		audioElementRef.current!.pause();
		if (isReady.current) {
			audioElementRef && audioElementRef.current && audioElementRef.current.play();
		} else {
			isReady.current = true;
		}
	}, [trackIndex, audioElementRef]);

	function scrollTo(id: string, offset = -80) {
		scroller.scrollTo(id, {
		  duration: 800,
		  delay: 0,
		  smooth: "easeInOutQuart",
		  offset: offset
		});
	}

	const MemoizedAudioTag = useMemo(
		() => (
		  <AudioTag
		  	audioElementRef={audioElementRef}
			audioSrc={audioSrc}
		  />
		),
		[
			audioElementRef,
			audioSrc
		],
	);

	return (
		<AudioPlayerContainer>
			<br />
			<div className="body">
				<div className="current-track-info">

					<img
						className="artwork"
						src={albumMetadata.albumCoverImages.sm}
						srcSet={`${albumMetadata.albumCoverImages.sm} 375w, ${albumMetadata.albumCoverImages.md} 750w, ${albumMetadata.albumCoverImages.lg} 1500w`}
						height="1000"
						width="1000"
						alt={`Album Artwork`}
					/>

					<h3 className="title">{title}</h3>

					{
						MemoizedAudioTag
					}

					{/* <audio
						id="audioPlayer"
						src={audioSrc}
						controls
						preload="auto"
						ref={audioElementRef}
					>
						Your browser does not support the audio element.
					</audio> */}
				</div>

				<div className="track-list">
					<table>
						<thead>
							{/* <tr>
								<th>
									Title
								</th>
								<th>
									Length
								</th>
								<th>
									Actions
								</th>
							</tr> */}
						</thead>
						<tbody>
							{
								tracks.map((i, idx) => {
									return (
										<tr key={i.title}
											id={idx === trackIndex ? 'currentTrack' : ''}
										>
											<td onClick={() => {
													setTrackIndex(idx)
												}}
												style={{
													cursor: 'pointer'
												}}
											>
												{i.title}
												&nbsp;
												<FaItunesNote/>
											</td>
											<td>
												{i.duration}
											</td>
											<td onClick={(e) => {
													scrollTo(i.title)
											}}>
												<CgNotes/>
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				</div>

			</div>
		</AudioPlayerContainer >
	);
}