import { ITrackMetadata } from './interfaces/trackMetadata';

export const Tom: ITrackMetadata = {
	"title": "Tom",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano", "Steffen Zeichner"],
	writing: ["Thadeus Duval"],
	"lyrics": [
		{
			"sectionName": "Intro",
			"lyrics": ""
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				"Love me, hold me",
				"Leave me, bleed me",
				"Love me, hold me",
				"Leave me, bleed me"
			]
		},
		{
			"sectionName": "Verse 1",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"Living life, living life, living in the moment",
						"Need it to, need it to, need it to be spoken",
						"Motherfuck a token, I'm like an omen",
						"Potent, rolling, I'll see you up in Oakland"
					]
				},
				{
					"sectionName": "Stanza 2",
					"lyrics": [
						"Caught his eye, caught his eye, caught it now he foaming",
						"Now we know, now we know, now we know he scrolling",
						"Never be controlling, now we blowing",
						"In over your head, we should call that shit a quotient"
					]
				},
				{
					"sectionName": "Stanza 3",
					"lyrics": [
						"Try to be, try to be, try to be consoling",
						"Gotta be, gotta be, gotta be patroling",
						"Finally, finally, finally we zoning",
						"Childlike, childlike, call me Nickelodeon"
					]
				},
				{
					"sectionName": "Stanza 4",
					"lyrics": [
						"Sublety, sublety, sublety is noted",
						"Gonna see, Gonna see, Gonna see me quoted",
						"Money ain't the motive, you know I voted",
						"Covid, doted, the other shit is bogus"
					]
				}
			]
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				"Love me, hold me",
				"Leave me, bleed me",
				"Love me, hold me",
				"Leave me, bleed me",
				"Love me, hold me",
				"Leave me, bleed me",
				"Love me, hold me (like I am the only one)",
				"Leave me, bleed me (the only one that you want for fun)"
			]
		}
	]
}