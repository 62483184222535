import { ITrackMetadata } from './interfaces/trackMetadata';

export const Merete: ITrackMetadata = {
	"title": "Merete",
	"engineering": ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	"production": ["Thadeus Duval", "Mauricio Cano", "Chris Valente"],
	"writing": ["Thadeus Duval", "Chris Valente"],
	"lyrics": [
		{
			"sectionName": "Intro",
			"lyrics": ""
		},
		{
			"sectionName": "Verse 1",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"It's time for me to let go",
						"I'm letting go of these elbows",
						"I don't want to see myself like this no more"
					]
				},
				{
					"sectionName": "Stanza 2",
					"lyrics": [
						"6 days by myself now",
						"And no doubt I'm (gonn)a get loud",
						"I think I'm seeing patterns in my lifestyle again"
					]
				}
			]
		},
		{
			"sectionName": "Interlude",
			"lyrics": ""
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"We got a whole (a)nother day to play",
						"I don't want to ever hear you say",
						"That you don't have the time of day",
						"Don't let these people tell you what you should or shouldn't do"
					]
				}
			]
		},
		{
			"sectionName": "Interlude",
			"lyrics": ""
		},
		{
			"sectionName": "Verse 2",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"I don't want to see you with him",
						"I just want to feel your skin",
						"I just want to know everything will be alright",
						"(Be)cause I just want to have you tonight"
					]
				},
				{
					"sectionName": "Guitar Solo",
					"lyrics": ""
				}
			]
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"We got a whole (a)nother day to play",
						"I don't want to ever hear you say",
						"That you don't have the time of day",
						"Don't let these people tell you what you should or shouldn't do",
						"We got a whole (a)nother day to play",
						"I don't want to ever hear you say",
						"That you don't have the time of day",
						"Don't let these people tell you what you should or shouldn't do"
					]
				}
			]
		},
		{
			"sectionName": "Interlude",
			"lyrics": ""
		},
		{
			"sectionName": "Outro",
			"lyrics": ""
		}
	]
}