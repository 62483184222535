import styled from 'styled-components'

export const NFTRow = styled.div`
  position: relative;
  padding: 0px;
  
  .container {
    height: calc(100vh - ${props => props.theme.topNavBarHeight});
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    .featured-nft-div {
      height: calc(50vh);
      display: flex;
      justify-content: center;
      align-items: center;
    
      img {
        border-radius: 0.2em;
        border: 1px solid rgba(0,0,0,0.1);
        display: block;
        /* margin: 1em; */
        width: auto;
        /* height: calc(33.0vh); */
        height: calc(50vh);

        :hover {
          border: 1px solid rgba(255,0,0,1.0);
        }

        :active {
          border: 1px solid rgba(0,0,255,1.0);
        }

      }
    
    }

  }
`