import styled from 'styled-components';
import NamesLandscape from '../../../assets/NamesLandscape.jpg';

export const BackgroundAlbumCover = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-image: url(${NamesLandscape});
  background-position: center;
  background-color: white;
  background-size: cover;
  opacity: 0.3;
  width: auto;
  height: auto;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: ${props => props.theme.topNavBarHeight};
  z-index: 0;
`