export enum Routes {
  Home = '/',
  Listen = '/listen',
  Logo = '/3d',
  NFT = '/nft',
}

export enum LandingPageSections {
  Stream = '#stream',
  Listen = '#listen',
  Logo = '#logo',
  Lyrics = "#lyrics"
}