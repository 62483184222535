import styled from 'styled-components';

export const SongLyricContainer = styled.div`
  text-align: center;
  width: 80%;
  margin: 0rem auto 2rem auto;

  .rootLevelSectionTitle {
    margin: 0.4rem;
  }

  .divider {
    height: 0.5rem;
  }

  .lyricsBody {
    background-color: white;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);
  }

  h1 {
    text-align: left;
  }

  h2 {
    font-size: 3rem;
    margin: 1rem;
    margin-top: 3rem;
  }

  p {
    font-size: 0.8rem;
    margin: 0.2rem;
  }
`