import { ITrackMetadata } from './interfaces/trackMetadata'

export const Austin: ITrackMetadata = {
	title: "Austin",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano", "David Cruz"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: ""
		},
		{
			sectionName: "Verse 1",
			lyrics: [
				{
					sectionName: "Stanza 1",
					lyrics: [
						"I ain't going to work another day in my life",
						"While you be fucking your wife, and I know it's",
						"All on me to fill this moment",
						"While your feeling so atrocious, yeah"
					]
				}
			]
		},
		{
			sectionName: "Interlude",
			lyrics: [
				"Oooh, Oooh, Oooh, Oooh",
				"Oooh, Oooh, Oooh, Oooh"
			]
		},
		{
			sectionName: "Verse 2",
			lyrics: [
				"11,000 days I found another way",
				"I love myself and my tribe, I'm just another gay",
				"I'm so glad that it's over",
				"All of these years in my mind was a boulder",
				"Didn't you know it was an evening snow",
				"Cool my balls with an evening blow",
				"11k days I found another way",
				"I'm in my place on my time blessed with another day"
			]
		},
		{
			sectionName: "Bridge",
			lyrics: [
				"Oooh, Oooh, Oooh, Oooh",
				"Oooh, Oooh, Oooh, Oooh",
				"All on me to fill this moment",
				"While your feeling so atrocious"
			]
		},
		{
			sectionName: "Out Chorus",
			lyrics: [
				"I got a feeling that this is where you want to be",
				"I got a feeling that, that you gon' come for free",
				"I got a feeling that this is where you want to be",
				"And I got a feeling that, that you gon' come for free",
				"I got a feeling that this is where you want to be",
				"I got a feeling that, that you gon' come for free",
				"I got a feeling that this is where you want to be",
				"And I got a feeling that, that you gon' come for free"
			]
		},
		{
			sectionName: "Outro (Instumental)",
			lyrics: ""
		}
	]
}