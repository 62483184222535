import * as React from "react";
import { Line, ToggleButton } from './styles';

interface Props {
  onClick: () => void
}

function drawerToggleButton(props: Props) {
  return (
    <ToggleButton onClick={props.onClick}>
      <Line />
      <Line />
      <Line />
    </ToggleButton>
  )
}

export default drawerToggleButton