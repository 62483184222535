import * as React from "react";

import {
  List,
  Nav,
  ListItem
} from './styles'

import { FaItunesNote, FaEthereum } from 'react-icons/fa';
// import { BiCubeAlt } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { CgNotes } from "react-icons/cg";

import { useHistory, useLocation } from "react-router-dom";

import { Routes } from "../Nav/supportedRoutes";

import { DefaultTheme } from 'styled-components'

interface Props {
  show: boolean,
  closeDrawer: () => void,
  refs: {
    section1Ref: React.RefObject<any>,
    section2Ref: React.RefObject<any>,
    section3Ref: React.RefObject<any>,
    section4Ref: React.RefObject<any>,
    section5Ref: React.RefObject<any>
  },
  theme: DefaultTheme
}

export function SideDrawer(props: Props) {
  const history = useHistory();

  const location = useLocation();
	const onHomePage = location.pathname === '/'

  const {
    refs: {
      // section1Ref,
      // section2Ref,
      section3Ref,
      section4Ref
    },
    show,
    theme
  } = props

  function sideNavOptionClicked( sectionRef: React.RefObject<any>, route: string) {
    
    props.closeDrawer()

    if (onHomePage) {
      const topNavBarHeight = (theme as any).topNavBarHeight;
      const topNavBarHeight_parsed = Number.parseFloat(topNavBarHeight.replace('px', ''))
      if (sectionRef && sectionRef.current) {
        window.scrollTo(0, (sectionRef.current.offsetTop - topNavBarHeight_parsed))
      }
    } else {
      history.push(route);
    }
    
  }

  return (
    <Nav show={show}>
      <List>
        <ListItem onClick={() => {
          
          sideNavOptionClicked(section3Ref, Routes.Home)

        }}>
          <AiOutlineHome />
          <span>&nbsp;Home</span>
        </ListItem>

        {
          onHomePage 
          &&
          (
            <>
              <ListItem onClick={() => {
                sideNavOptionClicked(section3Ref, Routes.Home)
              }}>
                <FaItunesNote />
                <span>&nbsp;Listen</span>
              </ListItem>
      
              <ListItem onClick={() => {
                sideNavOptionClicked(section4Ref, Routes.Home)
              }}>
                <CgNotes />
                <span>&nbsp;Lyrics</span>
              </ListItem>
            </>
          )
        }

        <ListItem onClick={() => {
          props.closeDrawer()

          history.push("/nft");
        }}>
          <FaEthereum />
          <span>&nbsp;NFT</span>
        </ListItem>

      </List>
    </Nav>
  )
}