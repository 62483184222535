import styled from 'styled-components';

export const Nav = styled.nav<{
  show: boolean
}>`
  height: 100%;
  background: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: ${props => props.show ? `translateX(0%)` : `translateX(-100%)`};
  transition: transform 0.3s ease-out;
`

export const List = styled.ul`
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const ListItem = styled.span`
  margin: 0.5rem 0;
  font-size: 1.2em;
  text-decoration: none;
  color: black;
  cursor: pointer;

  &:hover {
    color: red;
  }

  &:active {
    color: blue;
  }
  
`