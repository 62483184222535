import React from 'react'

import isObject from 'lodash/isObject'

import { AlbumMetadata } from '../../../data/AlbumMetadata'
import { ITrackMetadata } from '../../../data/AlbumMetadata/interfaces/trackMetadata'

import { SongLyricContainer } from './styles'

export function Lyrics() {

	function renderLyric(trackLyricTree: any, tree: any, suggestedKey: string) {

		if (Array.isArray(trackLyricTree.lyrics)) {
			const newNode = []
			for (let i = 0; i < trackLyricTree.lyrics.length; i++) {
				const node = renderLyric(trackLyricTree.lyrics[i], [], (suggestedKey + i))
				newNode.push(node)
			}
			tree.push(<div className={"section"} key={suggestedKey}>
				{newNode}
			</div>)
		} else if (isObject(trackLyricTree)) {
			const nodeChildren = renderLyric((trackLyricTree as ITrackMetadata).lyrics, [], suggestedKey)
			tree.push(<div className={"sub-section"} key={suggestedKey}>
				{nodeChildren}
			</div>)
		} else {
			if (trackLyricTree) {
				tree.push(<p key={suggestedKey}>
					{trackLyricTree}
				</p>)
			}
		}

		return tree
	}

	return (
		<SongLyricContainer>
			<h1>Track Info</h1>
			{
				AlbumMetadata.metadata.trackOrder.map((i: string, idx: number) => {
					
					return <div key={i} id={i}>
						<h2>{i}</h2>
						<p>Engineered By: {AlbumMetadata.trackMetadata[i]?.engineering?.join(', ')}</p>
						<p>Production By: {AlbumMetadata.trackMetadata[i]?.production?.join(', ')}</p>
						<p>Lyrics By: {AlbumMetadata.trackMetadata[i]?.writing?.join(', ')}</p>
						<div className={'lyricsBody'}>
							{
								AlbumMetadata.trackMetadata[i].lyrics.map((j: any, jdx: number) => {

									if (j.lyrics) {

										const lyricsTree = renderLyric(j, [], (j.sectionName + jdx))

										return <div key={j.sectionName + jdx}>
											<div className={"divider"} />
											<h5 className={'rootLevelSectionTitle'}><u>{j.sectionName}</u></h5>
											{lyricsTree}
											<div className={"divider"} />
										</div>

									} else {
										return null
									}

								})
							}
						</div>
					</div>
				})
			}
		</SongLyricContainer>
	)
}
