import { ITrackMetadata } from './interfaces/trackMetadata';

export const Papaya: ITrackMetadata = {
	title: "Papaya",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano", "Steffen Zeichner", "Jack Le Sante", "David Cruz"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: ""
		},
		{
			sectionName: "Verse 1",
			lyrics: [
				"Tonight, don't be polite",
				"Tonight, you don't have to fight"
			]
		},
		{
			sectionName: "Pre Chorus",
			lyrics: [
				"Round and round like a merry-go-round",
				"Town to town hangin upside down",
				"I will find you, I would like to",
				"Be by yourside, my precious child",
				"I know it can be done"
			]
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"It's time, for you",
				"To be the only thing I know",
				"It's time, for you"
			]
		},
		{
			sectionName: "Verse 2",
			lyrics: [
				"Stop thinking, stop blinking, it's for you",
				"Stop thinking, stop blinking, it's for you"
			]
		},
		{
			sectionName: "Bridge",
			lyrics: [
				"Round and round like a merry-go-round",
				"Town to town hangin upside down",
				"I will find you, I would like to",
				"Be by yourside, my precious child",
				"I know it can be done",
				"Round and round like a merry-go-round",
				"Town to town hangin upside down",
				"I will find you, I would like to",
				"Be by yourside, my precious child",
				"I know it can be done and I be like Danke schön"
			]
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"It's time, for you",
				"To be the only thing I know",
				"It's time, for you"
			]
		},
		{
			sectionName: "Out Chorus",
			lyrics: [
				"Round and round like a merry-go-round",
				"Town to town hangin upside down",
				"I will find you, I would like to",
				"Be by yourside, my precious child",
				"I know it can be done",
				"Round and round like a merry-go-round",
				"Town to town hangin upside down",
				"I will find you, I would like to",
				"Be by yourside, my precious child",
				"I know it can be done"
			]
		},
		{
			sectionName: "Outro",
			lyrics: ""
		}
	]
}