import styled from 'styled-components';

export const StreamServicesRow = styled.div`
  position: relative;
  /* background-color: green; */
  padding: 0px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  
  .icons-container {
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .svg-div {
      padding: 1em;
      width: 100%;
      height: calc((100vh - ${props => props.theme.topNavBarHeight}) / 3);
      display: flex;
      justify-content: center;
      /* background-color: green; */
      /* border: 1px solid black; */

      svg {
        width: auto;
        height: 100%;
      }
		}
  }

  @media (min-width: 576px) {
    .icons-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      .svg-div {
        padding: 1em;
        height: calc(100vh - ${props => props.theme.topNavBarHeight});
        display: flex;
        justify-content: center;
        
        svg {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`