import { ITrackMetadata } from './interfaces/trackMetadata';

export const Martin: ITrackMetadata = {
	title: "Martin",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: ""
		},
		{
			sectionName: "Verse 1",
			lyrics: [
				"It was only a matter of time",
				"Yeah ima eat shit ima shine",
				"Yeah ima believe in my mind",
				"(Be)cause all of my lesions (are) benign",
				"My cock ain't a mess it's a shrine",
				"And I ain't a tyrant I'm kind",
				"I gotta be quick when I grind",
				"Divine, defined",
				"Airports, airborne, don't fly, stay warm",
				"Rainstorms, Acorns, Gay Porn, Skateboards",
				"They'll laugh, I'll scorn",
				"Reborn, eat corn",
				"He stores, before",
				"You quit, eat more"
			]
		},
		{
			sectionName: "Interlude",
			lyrics: ""
		},
		{
			sectionName: "Verse 2",
			lyrics: [
				"Deep down all I want is a guy",
				"A bitch who sucks dick and some fries",
				"My motives is pure not maligned",
				"I think I'm gon' end on a high",
				"Yea we should fall out of the sky",
				"And shaking my ass ain't a crime",
				"And people be hitting my line",
				"Divine, defined",
				"Get yours, mentors",
				"Claymore, C-4",
				"Feet sore, we score",
				"Clarence Seedorf",
				"Endor, cared for",
				"Creedmoor, Precor",
				"Lyor, Tibor",
				"Seymour, Igor"
			]
		}
	]
}