import React from 'react';
import { AudioPlayer as AudioPlayerV3 } from './AudioPlayerV3/AudioPlayerV3';

import { tracks } from './tracks'

import AlbumCoverLg from '../../../assets/NamesAlbumCover3000x3000.jpg';
import AlbumCoverMd from '../../../assets/NamesAlbumCover1980x1980.jpg';
import AlbumCoverSm from '../../../assets/NamesAlbumCover990x990.jpg';

export function Listen() {

	return (
		<AudioPlayerV3 tracks={tracks} albumMetadata={{
			albumCoverImages: {
				sm: AlbumCoverSm,
				md: AlbumCoverMd,
				lg: AlbumCoverLg
			}
		}} />
	);

}