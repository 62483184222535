import { ITrackMetadata } from './interfaces/trackMetadata'

export const Walter: ITrackMetadata = {
	title: "Walter",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano", "Adrian Garcia"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: ""
		},
		{
			sectionName: "Verse 1",
			lyrics: [
				{
					sectionName: "Stanza 1",
					lyrics: [
						"My persona feels like it's about to die",
						"I don't really know why I have to try",
						"(And) bitch I'm leaving you behind bye-bye",
						"And bitch I'm half gay half bi motherfucker, yeah!"
					]
				},
				{
					sectionName: "Stanza 2",
					lyrics: [
						"Some parts of me ain't ever gonna change",
						"The other 9 percent of me gonna break me out these chains",
						"Put my dick inside my butt and we'll call it a day",
						"Thankful that you thought of me, I've never been enough",
						"Fuck me in my bussy, I'm just tryna pop a nut",
						"Pretty Puerto Ricans got me acting like I'm tough"
					]
				},
				{
					sectionName: "Stanza 3",
					lyrics: [
						"Love myself for being me, love myself like a deity",
						"Love myself cause I'm so dear to me, love myself not once but two or three",
						"Yea I'm sticking to my story, this that new found glory",
						"It's just me and my Nour-ie, Abe, Kent, Brent, and Corey",
						"I'm not sorry"
					]
				},
				{
					sectionName: "Stanza 4",
					lyrics: [
						"You can't do nothing for me, riding (a)round in a lorry",
						"No rings unlike Bob Horry"
					]
				}
			]
		},
		{
			sectionName: "Interlude",
			lyrics: ""
		},
		{
			sectionName: "Verse 2",
			lyrics: [
				{
					sectionName: "Stanza 1",
					lyrics: [
						"I'm hurting, I'm a virgin, still learning how to be certain",
						"And look inside your soul you'll see everything you need",
						"Death is the beginning, your already where you'll be",
						"Love is the key, amor pa ti, amor pa mi, claro que si"
					]
				},
				{
					sectionName: "Stanza 2",
					lyrics: [
						"Growing, flowing, showing, slowing, going",
						"Boating, floating, never gloating",
						"(And) once again I'm switching it up for you",
						"I just want to make a beautiful sound",
						"I just want to make a beautiful gown and beautiful town"
					]
				},
				{
					sectionName: "Stanza 3",
					lyrics: [
						"Time to break it down to make my ego understand",
						"That he's self destructive and he'll never be a man",
						"But I still love him, he's my number one fan",
						"Always think twice, read, and never overlook",
						"Crazy how much game I got from sticking to the book",
						"Yea I feed myself you can call me a cook"
					]
				},
				{
					sectionName: "Stanza 4",
					lyrics: [
						"I'll be breaking patterns just by writing all these songs",
						"What'll I find sitting inside all along",
						"While I built my life off of shit, that's wrong!"
					]
				}
			]
		}
	]
}