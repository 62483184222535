import * as React from "react";
// import {
// 	BackgroundAlbumCover
// } from '../../styled/BackgroundAlbumCover';

import { DefaultTheme } from 'styled-components'
import { NFTRow } from './styles'
import { Card } from '../../styled/Card'

// import NamesNFTLg from '../../../assets/NamesNFT.jpg';
// import NamesNFTMd from '../../../assets/NamesNFT.jpg';
import NamesNFTSm from '../../../assets/NamesNFT.jpg';

export interface NFTProps {
	theme: DefaultTheme
}

export function NFT(props: NFTProps) {
	
	return (
        <>
            {/* <BackgroundAlbumCover /> */}
            <NFTRow>
                <div className='container'>
                    <div className='featured-nft-div'>
                        <Card>
                            <img
                                onClick={() => {
                                    window.open(
                                        "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/26879490108594446731705827605851247102134399078179262394750375750421417295873",
                                        '_blank'
                                    )
                                }}
                                className="image"
                                src={NamesNFTSm}
                                srcSet={`${NamesNFTSm} 375w, ${NamesNFTSm} 750w, ${NamesNFTSm} 1500w`}
                                height="1000"
                                width="1000"
                                alt={`Names Landscape NFT`}
                            />
                        </Card>
                    </div>
                </div>
            </NFTRow>
        </>
	)

}