import { ITrackMetadata } from './interfaces/trackMetadata';

export const Rachel: ITrackMetadata = {
	title: "Rachel",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: "Now I'm like"
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"How you look so fine in your white vibration?",
				"I'm like damn you got some good citations!"
			]
		},
		{
			sectionName: "Interlude",
			lyrics: "(And) now I'm like"
		},
		{
			sectionName: "Verse",
			lyrics: [
				"Dig deeper, deeper than I ever have",
				"I'll dig deeper, deeper than the wisest old man",
				"Dig deeper, deeper than I ever have",
				"All I know is here and now (and now I'm like)"
			]
		},
		{
			sectionName: "Bridge",
			lyrics: ""
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"How you look so fine in your white vibration?",
				"I'm like damn you got some good citations!"
			]
		}
	]
}