import * as React from "react";
import {
	BackgroundAlbumCover
} from '../../styled/BackgroundAlbumCover';

import {
	FaSpotify,
	FaItunes
} from "react-icons/fa";

import { AiFillYoutube } from "react-icons/ai";

import { StreamServicesRow } from './styles/StreamServicesRow'
// import { LogoRow } from './styles/LogoRow'
import { LyricsRow } from './styles/LyricsRow'

import {
	MusicPlayerRow
} from './styles/MusicPlayerRow'

// import { Logo } from '../Logo/Logo'
import { Listen } from '../Listen/Listen';
import { Lyrics } from '../Lyrics/Lyrics';
import { DefaultTheme } from 'styled-components'

import { ScrollToTopButton } from '../../ScrollToTopButton'

export interface HomeProps {
	theme: DefaultTheme,
	refs: {
		section1Ref: React.RefObject<any>,
		section2Ref: React.RefObject<any>,
		section3Ref: React.RefObject<any>,
		section4Ref: React.RefObject<any>,
		section5Ref: React.RefObject<any>
	}
}

export function Home(props: HomeProps) {
	const {
		refs: {
			section1Ref,
			// section2Ref,
			section3Ref,
			section4Ref,
		}
	} = props

	return (
		<>

			<BackgroundAlbumCover />
			<ScrollToTopButton />
			
			<StreamServicesRow
				ref={section1Ref}
				id={"stream"}>
				<div className='icons-container'>
					{
						[
							<FaSpotify
								onClick={() => {
									// https://open.spotify.com/album/7wJDI4aeRXHvgtfxBTaBui?si=qBU8ohUAQNSTkliv4jAqKw&dl_branch=1
									window.open('https://open.spotify.com/album/7wJDI4aeRXHvgtfxBTaBui?si=qBU8ohUAQNSTkliv4jAqKw&dl_branch=1','_blank')
								}}
							/>,
							<FaItunes
								onClick={() => {
									// https://music.apple.com/us/album/names/1577252224
									window.open('https://music.apple.com/us/album/names/1577252224','_blank')
								}}
							/>,
							<AiFillYoutube
								onClick={() => {
									// https://music.youtube.com/watch?v=HAs07niRKwo&feature=share
									window.open('https://music.youtube.com/watch?v=HAs07niRKwo&feature=share','_blank')
								}}
							/>
						].map((i, idx) => {
							return (
								<div className="svg-div" key={idx}>
									{i}
								</div>
							)
						})
					}
				</div>
			</StreamServicesRow>

			<MusicPlayerRow
				ref={section3Ref}
				id={"listen"}>
				<Listen />
			</MusicPlayerRow>

			<LyricsRow
				ref={section4Ref}
				id={"lyrics"}>
				<div>
					<Lyrics></Lyrics>
				</div>
			</LyricsRow>

		</>
	)
}