import { ITrackMetadata } from './interfaces/trackMetadata';

export const Rene: ITrackMetadata = {
	title: "Rene",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: ""
		},
		{
			sectionName: "Verse 1",
			lyrics: [
				{
					sectionName: "Stanza 1",
					lyrics: [
						"I'm flying, I'm flying, I'm styling, I'm styling",
						"I'm whiling, I'm whiling, I'll buy it for you",
						"I'm flying, I'm flying, I'm styling, I'm styling",
						"I'm whiling, I'm whiling, I'll buy it for you"
					]
				},
				{
					sectionName: "Stanza 2",
					lyrics: [
						"I'm sighing, I'm sighing, I'm trying, I'm trying",
						"I'm crying, I'm crying, I'm dying for you",
						"I'm sighing, I'm sighing, I'm trying, I'm trying",
						"I'm crying, I'm crying, I'm dying for you"
					]
				}
			]
		},
		{
			sectionName: "Chorus (Instrumental)",
			lyrics: ""
		},
		{
			sectionName: "Verse 2",
			lyrics: [
				{
					sectionName: "Stanza 1",
					lyrics: [
						"I'm calling, I'm calling, I'm balling, I'm balling",
						"I'm stalling, I'm stalling, I'm falling for you",
						"I'm calling, I'm calling, I'm balling, I'm balling",
						"I'm stalling, I'm stalling, I'm falling for you"
					]
				},
				{
					sectionName: "Stanza 2",
					lyrics: [
						"Someone stop him, he does this too often",
						"He'll end up in a coffin, want him to start feeling new",
						"Somebody stop him, he does this too often",
						"He'll end up in a coffin, want him to start feeling new"
					]
				}
			]
		},
		{
			sectionName: "Chorus (Instrumental)",
			lyrics: ""
		}
	]
}