export interface Track {
  title: string,
  artist: string,
  audioSrc: string,
  color: string,
  duration: string
}

export const tracks: Track[] = [
  {
    title: 'Austin',
    artist: 'Thadeus',
    audioSrc: '/01 Austin.mp3',
    color: 'gold',
    duration: '2:40'
  },
  {
    title: 'Tom',
    artist: 'Thadeus',
    audioSrc: '/02 Tom.mp3',
    color: 'gold',
    duration: '2:06'
  },
  {
    title: 'Walter',
    artist: 'Thadeus',
    audioSrc: '/03 Walter.mp3',
    color: 'gold',
    duration: '3:19'
  },
  {
    title: 'Merete',
    artist: 'Thadeus',
    audioSrc: '/04 Merete.mp3',
    color: 'gold',
    duration: '3:29'
  },
  {
    title: 'Martin',
    artist: 'Thadeus',
    audioSrc: '/05 Martin.mp3',
    color: 'gold',
    duration: '2:21'
  },
  {
    title: 'Irma',
    artist: 'Thadeus',
    audioSrc: '/06 Irma.mp3',
    color: 'gold',
    duration: '2:39'
  },
  {
    title: 'Rachel',
    artist: 'Thadeus',
    audioSrc: '/07 Rachel.mp3',
    color: 'gold',
    duration: '2:48'
  },
  {
    title: 'Jimbo',
    artist: 'Thadeus',
    audioSrc: '/08 Jimbo.mp3',
    color: 'gold',
    duration: '2:59'
  },
  {
    title: 'Heart',
    artist: 'Thadeus',
    audioSrc: '/09 Heart.mp3',
    color: 'gold',
    duration: '2:27'
  },
  {
    title: 'Rene',
    artist: 'Thadeus',
    audioSrc: '/11 Rene.mp3',
    color: 'gold',
    duration: '2:56'
  },
  {
    title: 'Papaya',
    artist: 'Thadeus',
    audioSrc: '10 Papaya.mp3',
    color: 'gold',
    duration: '3:36'
  },
  {
    title: 'Ella',
    artist: 'Thadeus',
    audioSrc: '/12 Ella.mp3',
    color: 'gold',
    duration: '2:38'
  }
];