import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    height: 100%;
    margin-top: 80px;
    font-family: 'Roboto', Open-Sans, Helvetica, Sans-Serif;
  }
`;

export default GlobalStyle;