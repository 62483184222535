import styled from 'styled-components'

export const AudioPlayerContainer = styled.div`
  position: relative;
  height: calc(100%);
  width: calc(100%);
  padding: 1em;
  max-width: 1400px;
  margin: 0 auto;

  .body {
    background-color: white;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);

    .current-track-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      opacity: 1;
      margin: 0 auto;
      flex: 1;
      
      h2,h3 {
        text-align: center;
      }

      .artwork {
        border-radius: 0.4em;
        border: 1px solid grey;
        display: block;
        margin: 0.5em auto;
        width: 35%;
        height: auto;
      }

      .track-info {
        text-align: center;
        z-index: 1;
        position: relative;
      }

      .title {
        font-weight: 700;
        margin: .5em 0em;
      }

      .artist {
        font-weight: 300;
        margin-top: 0;
      }

      audio {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: 0.5em auto;
      }

      .progress {
        margin: 1em;
        display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
      }
    }

    .track-list {
      border: 1px solid rgba(0,0,0,0.3);
      background-color: white;
      width: 100%;
      margin: 0 auto;
      flex: 1;
      overflow: scroll;

      table {
        /* font-family: arial, sans-serif; */
        border-collapse: collapse;
        width: 100%;

        thead {}

        tbody {}
      }

      td, th {
        text-align: center;
        padding: 1em;
        border-bottom: 1px solid rgba(0,0,0,0.1)
      }

      tr:nth-child(even) {
        background-color: #efefef;
      }

      #currentTrack {
        background-color: skyblue;
      }
    }
  }

  @media (min-width: 576px) {
      .body {
        height: calc(100% - 2em);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;  
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.2);
      
        .track-list {
          border: none;
          border-left: 1px solid rgba(0,0,0,0.3);
          background-color: white;
          width: auto;
          height: 100%;
          margin: 0 auto;
          overflow: scroll;
        }
      }

    }

    @media (min-height: 570px) {
      .body {
        .current-track-info {
          .artwork {
              border-radius: 0.4em;
              border: 1px solid rgba(0,0,0,0.1);
              display: block;
              margin: 0.5em auto;
              width: 35%;
              height: auto;
          }
        }
      }
    }
`