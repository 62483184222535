import * as React from "react";
import {
	useLocation
} from "react-router-dom"

import { FaItunesNote, FaEthereum } from 'react-icons/fa';
// import { BiCubeAlt } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { CgNotes } from "react-icons/cg";

import {
	Toolbar,
	Navigation,
	Links,
	LinksR,
	Items,
	ListItem,
	Spacer,
	LogoBlock
} from './styles'

import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'

import { DefaultTheme } from 'styled-components';

interface NavProps {
	onClick: () => void,
	refs: {
		section1Ref: React.RefObject<any>,
		section2Ref: React.RefObject<any>,
		section3Ref: React.RefObject<any>
		section4Ref: React.RefObject<any>
	},
	theme: DefaultTheme
}

export function Nav(props: NavProps) {

	const {
		refs: {
			section1Ref,
			// section2Ref,
			section3Ref,
			section4Ref
		},
		theme
	} = props

	const location = useLocation();
	const onHomePage = location.pathname === '/'

	return (
		<Toolbar>
			<Navigation>
				<div>
					<DrawerToggleButton onClick={props.onClick} />
				</div>
				<LogoBlock>
					{
						onHomePage ?
						<Links onClick={() => {
							const topNavBarHeight = (theme as any).topNavBarHeight;
							const topNavBarHeight_parsed = Number.parseFloat(topNavBarHeight.replace('px', ''))
	
							if (section1Ref && section1Ref.current) {
								window.scrollTo(0, (section1Ref.current.offsetTop - topNavBarHeight_parsed))
							}
						}}>
							<AiOutlineHome />
						</Links>
						:
						<LinksR to="/">
							<AiOutlineHome />
						</LinksR>
					}
				</LogoBlock>
				<Spacer />
				<div>
					<Items>
						<ListItem>
							{
								onHomePage ?
								<Links onClick={() => {
									const topNavBarHeight = (theme as any).topNavBarHeight;
									const topNavBarHeight_parsed = Number.parseFloat(topNavBarHeight.replace('px', ''))
	
									if (section3Ref && section3Ref.current) {
										window.scrollTo(0, (section3Ref.current.offsetTop - topNavBarHeight_parsed))
									}
								}}>
									<FaItunesNote />
								</Links>
								:
								null
							}							
						</ListItem>
						{/* <ListItem>
							{
								onHomePage ?
								<Links onClick={() => {
									const topNavBarHeight = (theme as any).topNavBarHeight;
									const topNavBarHeight_parsed = Number.parseFloat(topNavBarHeight.replace('px', ''))
	
									if (section2Ref && section2Ref.current) {
										window.scrollTo(0, (section2Ref.current.offsetTop - topNavBarHeight_parsed))
									}
								}}>
									<BiCubeAlt />
								</Links>
								:
								null
							}
							
						</ListItem> */}
						<ListItem>
							{
								onHomePage ?
								<Links onClick={() => {
									const topNavBarHeight = (theme as any).topNavBarHeight;
									const topNavBarHeight_parsed = Number.parseFloat(topNavBarHeight.replace('px', ''))
	
									if (section4Ref && section4Ref.current) {
										window.scrollTo(0, (section4Ref.current.offsetTop - topNavBarHeight_parsed))
									}
								}}>
									<CgNotes />
								</Links>
								:
								null
							}							
						</ListItem>
						<ListItem >
							<LinksR to={"/nft"}>
								<FaEthereum/>
							</LinksR>
						</ListItem>

					</Items>
				</div>
			</Navigation>
		</Toolbar >
	)
}