import { ITrackMetadata } from './interfaces/trackMetadata';

export const Jimbo: ITrackMetadata = {
	title: "Jimbo",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "David Cruz"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: ""
		},
		{
			sectionName: "Verse 1",
			lyrics: [
				{
					sectionName: "Stanza 1",
					lyrics: [
						"I told you I meant it",
						"I told you I swept it",
						"I told you I'll spend it for you",
						"Emotions got blended",
						"It's time that I sent it",
						"I told you I'll rep you for you"
					]
				},
				{
					sectionName: "Stanza 2",
					lyrics: [
						"I told you I meant it",
						"I told you I swept it",
						"I told you I'll spend it for you",
						"Emotions got blended",
						"It's time that I sent it",
						"I told you I'll rep it all for you"
					]
				}
			]
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"I just want to feel your skin",
				"I just want to feel your skin up on mine",
				"I just want to feel your skin",
				"I just want to feel your skin up on mine"
			]
		},
		{
			sectionName: "Bridge",
			lyrics: [
				{
					sectionName: "Stanza 1",
					lyrics: [
						"It's time to say the things you've always wanted",
						"Hurry up before you are forgotten",
						"Hallelujah, Hallelujah, Hallelujah"
					]
				},
				{
					sectionName: "Stanza 2",
					lyrics: [
						"It's time to say the things you've always wanted",
						"Hurry up before you are forgotten",
						"Hallelujah, Hallelujah, Hallelujah"
					]
				}
			]
		},
		{
			sectionName: "Interlude",
			lyrics: [
				"I told you I meant it",
				"I told you I swept it",
				"I told you I'll spend it for you",
				"Emotions got blended",
				"It's time that I sent it",
				"I told you I'll rep you for you"
			]
		},
		{
			sectionName: "Verse 2",
			lyrics: [
				"I told you I meant it",
				"I told you I swept it",
				"I told you I'll spend it for you",
				"Emotions got blended",
				"It's time that I sent it",
				"I told you I'll rep it all for you"
			]
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"I just want to feel your skin",
				"I just want to feel your skin up on mine",
				"I just want to feel your skin",
				"I just want to feel your skin up on mine"
			]
		}
	]
}