import { ITrackMetadata } from './interfaces/trackMetadata';

export const Heart: ITrackMetadata = {
	title: "Heart",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano", "Keysel Pelaez"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			"sectionName": "Verse 1",
			"lyrics": [
				"Open Behinded, yeah I ain't sorry either",
				"This time he's gonna fuck me with his weiner",
				"Six signs man I don't give no damns",
				"Rewind (be)cause I'm 30 I'm a geezer"
			]
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				"Open Mind",
				"Open Heart, Open Mind",
				"Open Heart, Open Mind",
				"Open Heart, Open Mind"
			]
		},
		{
			"sectionName": "Verse 2",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"Enshrined, I don't follow as a leader",
						"Let's be honest you will never be one either",
						"I know that I'm still living with my mom",
						"Stop now (be)cause this song is only getting weirder"
					]
				},
				{
					"sectionName": "Stanza 2",
					"lyrics": [
						"Send out that text it's time I'm ready to receive her",
						"All these mistakes I've made have left my vision clearer",
						"You don't need to trust me, I will make you a believer",
						"It's no surprise each time he moves he moves in nearer"
					]
				}
			]
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				"Open Mind",
				"Open Heart, Open Mind",
				"Open Heart, Open Mind",
				"Open Heart, Open Mind"
			]
		},
		{
			"sectionName": "Verse 3",
			"lyrics": [
				"Ya'll don't even know, this ain't nothing but a teaser",
				"Shouts out to Grover, your a closer I'm a cleaner",
				"Slow down or you'll give yourself a fever",
				"Give me a break, I'm only trying to be a healer"
			]
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				"Open Mind",
				"Open Heart, Open Mind",
				"Open Heart, Open Mind",
				"Open Heart, Open Mind"
			]
		}
	]
}