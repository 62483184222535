import { ITrackMetadata } from './interfaces/trackMetadata';

export const Irma: ITrackMetadata = {
	"title": "Irma",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano", "David Cruz"],
	writing: ["Thadeus Duval" ],
	"lyrics": [
		{
			"sectionName": "Intro",
			"lyrics": ""
		},
		{
			"sectionName": "Verse 1",
			"lyrics": "(It) don't matter anyway"
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"I feel like it could have been better to go against them all",
						"I feel like it could have been better to go against them all"
					]
				}
			]
		},
		{
			"sectionName": "Interlude",
			"lyrics": [
				"The only time wasted was the time under cover",
				"If only I faced it while you all watched me suffer",
				"The only time wasted was the time under cover",
				"If only I faced it while you all watched me suffer"
			]
		},
		{
			"sectionName": "Bridge",
			"lyrics": ""
		},
		{
			"sectionName": "Interlude",
			"lyrics": ""
		},
		{
			"sectionName": "Verse 2",
			"lyrics": [
				"Jump in, jump in",
				"Don't kill yourself (be)cause you ain't",
				"Fallen, fallen",
				"(It) don't matter anyway"
			]
		},
		{
			"sectionName": "Chorus",
			"lyrics": [
				{
					"sectionName": "Stanza 1",
					"lyrics": [
						"I feel like it could have been better to go against them all",
						"I feel like it could have been better to go against them all"
					]
				}
			]
		},
		{
			"sectionName": "Interlude (Outro)",
			"lyrics": [
				"The only time wasted was the time under cover",
				"If only I faced it while you all watched me suffer"
			]
		}
	]
}