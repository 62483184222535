import * as React from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize'
import { Route, Switch } from 'react-router-dom';
import { Home } from './Pages/Home';
import { Nav } from './Nav';
import { SideDrawer } from './SideDrawer';
import { Backdrop } from './Backdrop';
import GlobalStyle from './globalStyles';
import { NotFound } from './Pages/NotFound/NotFound';
// import { Listen } from './Pages/Listen/Listen';
// import { Logo } from './Pages/Logo/Logo';
import { NFT } from './Pages/NFT';
import { Routes } from './Nav/supportedRoutes';

export const myTheme: DefaultTheme = {
  borderRadius: '5px',
  topNavBarHeight: '80px',
  colors: {
    main: "purple",
    secondary: "mediumseagreen",
  }
};

const App = (props: any) => {
  const [open, setOpen] = React.useState(false)

  const section1Ref = React.useRef(null);
  const section2Ref = React.useRef(null);
  const section3Ref = React.useRef(null);
  const section4Ref = React.useRef(null);
  const section5Ref = React.useRef(null);

  const sectionRefs = {
    section1Ref,
    section2Ref,
    section3Ref,
    section4Ref,
    section5Ref
  }

  return (
    <>
      <ThemeProvider theme={myTheme}>
        <Normalize />

        <GlobalStyle />
        <Nav
          refs={sectionRefs}
          onClick={() => setOpen(!open)}
          theme={myTheme}
        />
        <SideDrawer
          refs={sectionRefs}
          show={open}
          theme={myTheme}
          closeDrawer={() => setOpen(false)} />
        {
          open &&
          <Backdrop onClick={() => setOpen(false)} />
        }
        <main
          style={{
            height: 'calc(100vh - 80px)'
          }}
        >
          <Switch>
            <Route
              path={Routes.Home}
              exact
              // component={Home}
              render={() => <Home
                theme={myTheme}
                refs={sectionRefs} />}
            />
            <Route path={Routes.NFT} component={NFT} />
            {/* <Route path={Routes.Logo} component={Logo} /> */}
            <Route component={NotFound} />
          </Switch>
        </main>
      </ThemeProvider>
    </>
  );
}

export default App;