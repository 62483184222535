import { ITrackMetadata } from './interfaces/trackMetadata';

export const Ella: ITrackMetadata = {
	title: "Ella",
	engineering: ["Mauricio Cano", "Pedro 'Waldy' Dominguez"],
	production: ["Thadeus Duval", "Mauricio Cano", "Keysel Pelaez", "Jack Le Sante", "Joy Soto"],
	writing: ["Thadeus Duval"],
	lyrics: [
		{
			sectionName: "Intro",
			lyrics: ""
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"You have been warned, so heal your wounds and never go there",
				"This is time to hear their thoughts and let them breathe",
				"You have been warned, so heal your wounds and never go there",
				"This is time to hear their thoughts and let them breathe"
			]
		},
		{
			sectionName: "Refrain",
			lyrics: [
				"I come alive again",
				"I come alive again",
				"I come alive again"
			]
		},
		{
			sectionName: "Bridge",
			lyrics: ""
		},
		{
			sectionName: "Chorus",
			lyrics: [
				"You have been warned, so heal your wounds and never go there",
				"This is time to hear their thoughts and let them breathe",
				"You have been warned, so heal your wounds and never go there",
				"This is time to hear their thoughts and let them breathe"
			]
		},
		{
			sectionName: "Refrain",
			lyrics: [
				"I come alive again",
				"I come alive again",
				"I come alive again"
			]
		}
	]
}